import { render, staticRenderFns } from "./InvoiceMail.vue?vue&type=template&id=fef9789e&scoped=true&"
import script from "./InvoiceMail.vue?vue&type=script&lang=js&"
export * from "./InvoiceMail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fef9789e",
  null
  
)

export default component.exports