<template>
  <a-modal
    :maskClosable="false"
    :title="handle == 'info' ? '查询' : '邮寄'"
    style="top: 8px"
    :width="1200"
    v-model="visible"
    @ok="onSubmit"
    @cancel="toCancel"
    :footer="handle == 'info' ? null : o"
  >
    <a-tabs default-active-key="1" size="small" type="card">
      <a-tab-pane key="1" tab="物流信息" force-render v-if="info.invoiceStatus != 1 || handle == 'mail'">
        <a-form-model
          ref="form"
          :model="form"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 20 }"
          v-if="handle == 'mail'"
        >
          <a-row :gutter="4">
            <a-col :md="12">
              <a-form-model-item
                label="快递查询类型"
                prop="expressSearchType"
                :labelCol="{ span: 8, offset: 0 }"
                :wrapperCol="{ span: 16, offset: 0 }"
              >
                <a-select
                  style="width: 120px"
                  @change="selectExpressSearchType"
                  :disabled="handle == 'info'"
                  :defaultValue="handle == 'info' ? form.expressSearchType : '请选择'"
                >
                  <a-select-option :value="1">自动查询</a-select-option>
                  <a-select-option :value="2">手动查询</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item
                label="物流公司"
                prop="expressCompany"
                :labelCol="{ span: 8, offset: 0 }"
                :wrapperCol="{ span: 16, offset: 0 }"
              >
                <a-select
                  style="width: 120px"
                  @change="selectExpressCompany"
                  :disabled="handle == 'info'"
                  :defaultValue="handle == 'info' ? form.expressCompanyId : '请选择'"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in expressCompany" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="4">
            <a-col :md="12">
              <a-form-model-item
                label="快递单号"
                prop="expressCode"
                :rules="[{ required: true, message: '请输入快递单号', trigger: 'blur' }]"
                :labelCol="{ span: 8, offset: 0 }"
                :wrapperCol="{ span: 16, offset: 0 }"
              >
                <a-input
                  placeholder="请输入快递单号"
                  v-model.trim="form.expressCode"
                  :disabled="handle == 'info'"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item
                label="物流信息"
                prop="acceptStation"
                v-if="form.expressSearchType == 2"
                :rules="[{ required: true, message: '请输入物流信息', trigger: 'blur' }]"
                :labelCol="{ span: 8, offset: 0 }"
                :wrapperCol="{ span: 16, offset: 0 }"
              >
                <a-textarea
                  placeholder="请输入物流信息"
                  v-model.trim="form.acceptStation"
                  :disabled="handle == 'info'"
                ></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="4">
            <a-col :md="24">
              <a-form-model-item label="附件" v-if="form.expressSearchType == 2">
                <QiniuUpload
                  type="image"
                  :value.sync="form.logisticsPicture"
                  :size="3"
                  :disabled="handle == 'info'"
                ></QiniuUpload>
                <div class="upload-hint-text">支持扩展名：.jpg .png</div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div v-if="handle == 'info'">
          <a-divider orientation="left">快递公司</a-divider>
          <a-descriptions :column="3" size="small">
            <a-descriptions-item label="快递查询类型">{{
              info.expressSearchType == 1 ? '自动查询' : '手动查询'
            }}</a-descriptions-item>
            <a-descriptions-item label="物流公司">{{ info.expressCompany }}</a-descriptions-item>
            <a-descriptions-item label="快递单号">{{ info.expressCode }}</a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left">物流信息</a-divider>
          <a-timeline>
            <a-timeline-item v-for="item in tableData" :key="index">
              <div v-if="item.logisticsPicture">
                <ZoomMedia
                  style="width: 50px; float: left"
                  :value="item2"
                  v-for="(item2, index2) in item.logisticsPicture.split(',')"
                  :key="index2"
                ></ZoomMedia
                >
              </div>
              {{ item.acceptStation }}<br />
              {{ item.createTime }}
            </a-timeline-item>
          </a-timeline>
          <!-- <a-table size="small" :data-source="tableData" bordered rowKey="id" :pagination="false">
            <a-table-column
              title="序号"
              data-index="index"
              :width="60"
              align="center"
              :customRender="
                (text, record, index) => {
                  return index + 1
                }
              "
            >
            </a-table-column>
            <a-table-column title="物流状态" data-index="action" :width="200" :ellipsis="true" align="left">
            </a-table-column>
            <a-table-column title="物流信息" data-index="acceptStation" :width="200" :ellipsis="true" align="left">
            </a-table-column>
            <a-table-column title="所在城市" data-index="location" :width="200" :ellipsis="true" align="left">
            </a-table-column>
            <a-table-column title="附件" data-index="logisticsPicture" :width="140" :ellipsis="true" align="left">
              <template slot-scope="text, record" v-if="text">
                <ZoomMedia
                  style="width: 50px; float: left"
                  :value="item"
                  v-for="(item, index) in text.split(',')"
                  :key="index"
                ></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="操作时间" data-index="createTime" :width="120" :ellipsis="true" align="left">
            </a-table-column>
            <a-table-column title="操作" :width="40" :ellipsis="true" align="center">
              <template slot-scope="text, record">
                <a @click="delWLInfo(record)">删除</a>
              </template>
            </a-table-column>
          </a-table> -->
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="发票信息" force-render>
        <a-divider orientation="left">发票信息</a-divider>
        <a-descriptions :column="2" size="small">
          <!-- <a-descriptions-item label="开票主体">三菱</a-descriptions-item>
          <a-descriptions-item label=""></a-descriptions-item> -->
          <a-descriptions-item label="发票类型">
            {{ info.invoiceType }}
          </a-descriptions-item>
          <a-descriptions-item label="抬头类型">{{ info.receivingType == 1 ? '个人' : '公司' }}</a-descriptions-item>
          <a-descriptions-item :label="info.receivingType == 1 ? '发票抬头' : '公司名称'">{{
            info.invoiceTitle
          }}</a-descriptions-item>
          <a-descriptions-item label="纳税人识别码" v-if="info.receivingType == 2">{{
            info.taxpayerNumber
          }}</a-descriptions-item>
          <a-descriptions-item label="注册电话" v-if="info.receivingType == 2">{{ info.regPhone }}</a-descriptions-item>
          <a-descriptions-item label="注册地址" v-if="info.receivingType == 2">{{
            info.regAddress
          }}</a-descriptions-item>
          <a-descriptions-item label="开户银行" v-if="info.receivingType == 2">{{
            info.depositBank
          }}</a-descriptions-item>
          <a-descriptions-item label="银行账号" v-if="info.receivingType == 2">{{
            info.bankAccount
          }}</a-descriptions-item>
          <a-descriptions-item label="税率">
            {{ info.taxRate }}
          </a-descriptions-item>
          <a-descriptions-item label="开票内容">
            {{ info.invoiceContent }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider orientation="left" v-if="info.remark">发票备注</a-divider>
        <a-descriptions>
          <a-descriptions-item v-if="info.remark">
            {{ info.remark }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider orientation="left">邮寄地址</a-divider>
        <a-descriptions :column="1" size="small">
          <a-descriptions-item label="收货人">
            {{ info.consignee }}
          </a-descriptions-item>
          <a-descriptions-item label="收货人手机号码">
            {{ info.consigneePhone }}
          </a-descriptions-item>
          <a-descriptions-item label="收货地址">
            {{ info.province }}{{ info.city }}{{ info.area }}{{ info.address }}
          </a-descriptions-item>
        </a-descriptions>
      </a-tab-pane>
      <a-tab-pane key="3" tab="订单详情" force-render>
        <div v-if="info.details">
          <a-table :data-source="info.details" bordered rowKey="shipId" :pagination="false">
            <a-table-column title="发货单号" data-index="shipCode" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="实际金额" data-index="payPrice" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="可开票金额" data-index="invoicePrice" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="商品总数" data-index="goodsCount" :width="100" :ellipsis="true" align="left" />
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { idcard } from '@/utils/rules'
const qs = require('qs')
const columns = [
  {
    title: '发票抬头',
    dataIndex: 'invoiceTitle',
  },
  {
    title: '抬头类型',
    dataIndex: 'receivingType',
    key: 'receivingType',
    customRender: function (text) {
      if (text == 1) {
        return '个人'
      } else {
        return '公司'
      }
    },
  },
  {
    title: '纳税人识别号',
    dataIndex: 'taxpayerNumber',
  },
  {
    title: '开户银行名称',
    dataIndex: 'depositBank',
  },
  {
    title: '基本开户账号',
    dataIndex: 'bankAccount',
  },
  {
    title: '注册场所地址',
    dataIndex: 'regAddress',
  },
  {
    title: '注册电话',
    dataIndex: 'regPhone',
  },
]
export default {
  data() {
    return {
      form: {
        expressCompany: '',
        expressCompanyId: 0,
        expressCompanyCode: '',
        acceptStation: '',
        logisticsPicture: '',
        invoiceId: 0,
        expressSearchType: 0,
      },
      rowData: {},
      info: {},
      shipList: [],
      shipInfo: {},
      visible: false,
      logList: [],
      queryParam: {},
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      handle: '',
      InvoiceType: [],
      InvoiceTaxRate: [],
      chooseTitleVisible: false,
      chooseTitleConfirmLoading: false,
      columns,
      chooseTitleData: [],
      selectedRowKeys: [],
      expressCompany: [],
      tableData: [],
    }
  },
  methods: {
    //选择快递查询类型
    selectExpressSearchType(value) {
      console.log(`selectExpressCompany ${value}`)
      this.form.expressSearchType = value
      this.axios.get(`/api/order/logistics/logisticsCompany/list?pageSize=1000&queryType=` + value).then((res) => {
        if (res.code == 200) {
          this.expressCompany = res.body.records
        }
      })
    },
    //删除物流信息
    delWLInfo(row) {
      this.$confirm({
        title: '确定删除该条记录',
        onOk: () => {
          this.axios
            .get(`/api/order/invoice/orderShipInfo/del/invoiceLogistics?id=${row.id}`)
            .then((res) => {
              this.$message.success(res.message)
              this.showInfo(this.info.id, 'info')
            })
            .catch((err) => {})
        },
      })
    },
    //选择物流公司
    selectExpressCompany(value) {
      console.log(`selectExpressCompany ${value}`)
      this.form.expressCompanyId = value
    },
    //选择标题
    chooseTitleOnChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    //选择抬头取消事件
    chooseTitleHandleCancel() {
      this.chooseTitleVisible = false
    },
    //选择抬头确定事件
    chooseTitleHandleOk() {
      if (this.selectedRowKeys.length > 0) {
        const chooseId = this.selectedRowKeys[0]
        for (let i = 0; i < this.chooseTitleData.length; i++) {
          if (this.chooseTitleData[i].id == chooseId) {
            this.$set(this.info, 'invoiceTitle', this.chooseTitleData[i].invoiceTitle)
            this.$set(this.info, 'receivingType', this.chooseTitleData[i].receivingType)
            this.$set(this.info, 'taxpayerNumber', this.chooseTitleData[i].taxpayerNumber)
            this.$set(this.info, 'depositBank', this.chooseTitleData[i].depositBank)
            this.$set(this.info, 'bankAccount', this.chooseTitleData[i].bankAccount)
            this.$set(this.info, 'regAddress', this.chooseTitleData[i].regAddress)
            this.$set(this.info, 'regPhone', this.chooseTitleData[i].regPhone)
            this.chooseTitleVisible = false
            return
          }
        }
      } else {
        this.$message.warning('请选择一条数据')
      }
    },
    //选择发票抬头
    chooseInvoiceTitle() {
      this.chooseTitleVisible = true
    },
    InvoiceTypeHandleChange(value) {
      console.log(`InvoiceTypeHandleChange ${value}`)
      this.info.invoiceTypeId = value
    },
    invoiceContentTypeHandleChange(value) {
      console.log(`invoiceContentTypeHandleChange ${value}`)
      this.info.invoiceContentType = value
    },
    taxRateIdHandleChange(value) {
      console.log(`taxRateIdHandleChange ${value}`)
      this.info.taxRateId = value
    },
    toCancel() {
      this.visible = false
    },
    onSubmit() {
      //邮寄按钮
      if (this.form.expressSearchType == 0) {
        return this.$notification.error({ message: '请选择快递查询类型' })
      }
      if (this.form.expressCompanyId == 0) {
        return this.$notification.error({ message: '请选择物流公司' })
      }
      this.form.invoiceId = this.info.id
      this.axios.post('/api/order/invoice/orderShipInfo/mailSend/invoiceLogistics', this.form).then((res) => {
        if (res.code == 200) {
          this.$notification.success({ message: res.message })
          this.visible = false
          this.$emit('change')
        }
      })
    },
    showInfo(id, name) {
      this.handle = name
      this.visible = true
      this.axios
        .get(`/api/order/invoice/orderShipInfo/selectById/${id}`)
        .then((res) => {
          if (res.code == 200) {
            this.info = res.body
          }
        })
        .catch((err) => {})
      this.axios
        .get(`/api/order/invoice/orderShipInfo/get/invoiceLogistics?id=${id}`)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.body
          }
        })
        .catch((err) => {})
    },
  },
  created() {
    this.axios.get(`/api/order/logistics/logisticsCompany/list?pageSize=1000`).then((res) => {
      if (res.code == 200) {
        this.expressCompany = res.body.records
      }
    })
  },
}
</script>

<style lang="less" scoped>
.shipments-bts {
  text-align: right;
  // margin-top: 20px;
}
</style>
